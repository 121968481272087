@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo-wrap{
  width: 218px;
  display: flex;
  padding: 16px;
  pointer-events: none;
}
.logo-small{
  height: 50px;
  width: auto;
}
.logo-right{
  height: 50px;
  width: auto;
}
.none{
  display: none;
}
.header{
  padding-left: 24px;
}
.center-element{
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-page{
  width: 100vw;
  height: 100vh;
}
.flex{
  display: flex;
  flex-wrap: wrap;
}
.flex-between{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-align-center{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.flex-column-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-between-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.model-title{
  color: rgba(0,0,0,0.85);
  font-weight: 500;
  font-size: 20px;
  margin-right: 20px;
}
.blocks{
  margin: 24px 16px;
  padding: 24px;
  background: #fff
}